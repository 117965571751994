var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    [
      _c(
        "e-filters",
        {
          attrs: { searching: _vm.fetching },
          on: { search: _vm.filterData, reset: _vm.resetFiltersLocal },
          scopedSlots: _vm._u([
            {
              key: "after_buttons",
              fn: function () {
                return [
                  _c("e-button", {
                    staticClass: "ml-1",
                    attrs: {
                      busy: _vm.downloading,
                      text: _vm.$t("Baixar Relatório"),
                      variant: "primary",
                      icon: "download",
                    },
                    on: { click: _vm.onDownloadReport },
                  }),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          _c(
            "div",
            [
              _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    { attrs: { md: "4" } },
                    [
                      _c("e-store-combo", {
                        attrs: { required: false },
                        model: {
                          value: _vm.filters.storeId,
                          callback: function ($$v) {
                            _vm.$set(_vm.filters, "storeId", $$v)
                          },
                          expression: "filters.storeId",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { attrs: { md: "3" } },
                    [
                      _c("FormulateInput", {
                        attrs: {
                          id: "invoiceNumber",
                          type: "text",
                          label: _vm.$t("Número da NF"),
                        },
                        model: {
                          value: _vm.filters.invoiceNumber,
                          callback: function ($$v) {
                            _vm.$set(_vm.filters, "invoiceNumber", $$v)
                          },
                          expression: "filters.invoiceNumber",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { attrs: { md: "3" } },
                    [
                      _c("FormulateInput", {
                        attrs: {
                          id: "invoiceStatus",
                          placeholder: _vm.$t("Status"),
                          type: "vue-select",
                          label: _vm.$t("Status da Nota"),
                          options: _vm.statusInvoiceOptions(),
                        },
                        model: {
                          value: _vm.filters.status,
                          callback: function ($$v) {
                            _vm.$set(_vm.filters, "status", $$v)
                          },
                          expression: "filters.status",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { attrs: { md: "2" } },
                    [
                      _c("FormulateInput", {
                        attrs: {
                          id: "sale-id",
                          type: "number",
                          label: _vm.$t("Id da Venda"),
                          placeholder: _vm.$t("Id da Venda"),
                          instructions: [
                            {
                              text: _vm.$t(
                                "Auxilia na busca de NFe que possui alguma referência com venda. Ex:"
                              ),
                            },
                            {
                              text: _vm.$t(
                                "NFe que foi gerada a partir de cupom fiscal"
                              ),
                            },
                            { text: _vm.$t("NFe que criou venda") },
                            {
                              text: _vm.$t(
                                "NFe de cancelamento de venda fora do prazo da SEFAZ"
                              ),
                            },
                          ],
                        },
                        model: {
                          value: _vm.filters.saleId,
                          callback: function ($$v) {
                            _vm.$set(_vm.filters, "saleId", $$v)
                          },
                          expression: "filters.saleId",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { attrs: { md: "4" } },
                    [
                      _c("FormulateInput", {
                        attrs: {
                          id: "issueDate",
                          type: "date-range-picker",
                          label: _vm.$t("Período de emissão"),
                        },
                        model: {
                          value: _vm.filters.issueDate,
                          callback: function ($$v) {
                            _vm.$set(_vm.filters, "issueDate", $$v)
                          },
                          expression: "filters.issueDate",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { attrs: { md: "4" } },
                    [
                      _c("FormulateInput", {
                        attrs: {
                          id: "movementDate",
                          type: "date-range-picker",
                          label: _vm.$t("Período de movimentação"),
                        },
                        model: {
                          value: _vm.filters.movementDate,
                          callback: function ($$v) {
                            _vm.$set(_vm.filters, "movementDate", $$v)
                          },
                          expression: "filters.movementDate",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { attrs: { md: "4" } },
                    [
                      _c("e-person-search", {
                        attrs: {
                          label: _vm.$t("Pesquisar fornecedor"),
                          "is-supplier": "",
                          "only-active": false,
                          "value-is-object": "",
                        },
                        model: {
                          value: _vm.filters.supplier,
                          callback: function ($$v) {
                            _vm.$set(_vm.filters, "supplier", $$v)
                          },
                          expression: "filters.supplier",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { attrs: { md: "4" } },
                    [
                      _c("e-person-search", {
                        attrs: {
                          label: _vm.$t("Pesquisar cliente"),
                          "is-customer": "",
                          "only-active": false,
                          "value-is-object": "",
                        },
                        model: {
                          value: _vm.filters.customer,
                          callback: function ($$v) {
                            _vm.$set(_vm.filters, "customer", $$v)
                          },
                          expression: "filters.customer",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { attrs: { md: "4" } },
                    [
                      _c("FormulateInput", {
                        attrs: {
                          id: "nfeAccessKey",
                          type: "text",
                          label: _vm.$t("Chave da NFe"),
                        },
                        model: {
                          value: _vm.filters.nfeAccessKey,
                          callback: function ($$v) {
                            _vm.$set(_vm.filters, "nfeAccessKey", $$v)
                          },
                          expression: "filters.nfeAccessKey",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { attrs: { md: "4" } },
                    [
                      _c("FormulateInput", {
                        attrs: {
                          id: "invoice-invoice-natureOperation",
                          type: "vue-select",
                          options: _vm.getComboCfopNatureOperation,
                          label: _vm.$t("Código CFOP"),
                          instruction: _vm.$t(
                            "Filtra notas que contém algum item com o CFOP informado"
                          ),
                        },
                        on: { input: _vm.onSelectNatureOperation },
                        model: {
                          value: _vm.filters.cfop,
                          callback: function ($$v) {
                            _vm.$set(_vm.filters, "cfop", $$v)
                          },
                          expression: "filters.cfop",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { attrs: { md: "4" } },
                    [
                      _c("FormulateInput", {
                        attrs: {
                          id: "loss_reason",
                          label: _vm.$t("Motivo da baixa de estoque"),
                          type: "vue-select",
                          placeholder: _vm.$t("Selecione"),
                          options: _vm.invoiceStockLossesReasonOptions(),
                          instruction: _vm.$t(
                            "Filtra notas que contém algum item com o motivo informado"
                          ),
                        },
                        model: {
                          value: _vm.filters.stockLossesReason,
                          callback: function ($$v) {
                            _vm.$set(_vm.filters, "stockLossesReason", $$v)
                          },
                          expression: "filters.stockLossesReason",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
      _c(
        "b-card-actions",
        {
          attrs: { "action-refresh": "", "show-loading": _vm.fetching },
          on: { refresh: _vm.filterData },
        },
        [
          _c(
            "div",
            { attrs: { slot: "title" }, slot: "title" },
            [
              _c("e-page-size-selector", {
                attrs: { "per-page": _vm.paging.pageSize },
                on: { change: _vm.pageSizeChange },
              }),
            ],
            1
          ),
          _c("b-table", {
            ref: "invoices-table",
            staticClass: "bordered",
            attrs: {
              "show-empty": "",
              responsive: "",
              striped: "",
              "empty-text": _vm.getEmptyTableMessage(
                _vm.$tc("INVOICE.NAME"),
                "female"
              ),
              fields: _vm.fields,
              items: _vm.invoices,
              "no-local-sorting": "",
              "sort-by": _vm.sorting.sortBy,
              "sort-desc": _vm.sorting.sortDesc,
            },
            on: {
              "update:sortBy": function ($event) {
                return _vm.$set(_vm.sorting, "sortBy", $event)
              },
              "update:sort-by": function ($event) {
                return _vm.$set(_vm.sorting, "sortBy", $event)
              },
              "update:sortDesc": function ($event) {
                return _vm.$set(_vm.sorting, "sortDesc", $event)
              },
              "update:sort-desc": function ($event) {
                return _vm.$set(_vm.sorting, "sortDesc", $event)
              },
              "sort-changed": _vm.getData,
            },
            scopedSlots: _vm._u([
              {
                key: "cell(action)",
                fn: function (row) {
                  return [
                    _c("e-grid-actions", {
                      attrs: {
                        "show-update": false,
                        "show-delete": false,
                        buttons: _vm.gridButtons(row),
                      },
                      on: {
                        edit: function ($event) {
                          return _vm.onUpdateInvoice(row)
                        },
                        "consult-invoice": function ($event) {
                          return _vm.onConsultInvoice(row)
                        },
                        "download-pdf": function ($event) {
                          return _vm.onDownloadPdf(row)
                        },
                        "download-xml": function ($event) {
                          return _vm.onDownloadXml(row)
                        },
                      },
                    }),
                  ]
                },
              },
              {
                key: "head(status)",
                fn: function (ref) {
                  var label = ref.label
                  return [
                    _c(
                      "span",
                      [
                        _c("e-instructions", {
                          attrs: {
                            target: "instruction_status",
                            "container-class": "mr-0",
                            instructions: [
                              {
                                text: _vm.$t(
                                  "INVOICE.TABLE_INSTRUCTION.STATUS_1"
                                ),
                              },
                              {
                                text: _vm.$t(
                                  "INVOICE.TABLE_INSTRUCTION.STATUS_2"
                                ),
                              },
                              {
                                text: _vm.$t(
                                  "INVOICE.TABLE_INSTRUCTION.STATUS_3"
                                ),
                              },
                            ],
                          },
                        }),
                        _vm._v(" " + _vm._s(label.toUpperCase()) + " "),
                      ],
                      1
                    ),
                  ]
                },
              },
              {
                key: "head(invoiceNumber)",
                fn: function (ref) {
                  var label = ref.label
                  return [
                    _c(
                      "span",
                      [
                        _c("e-instructions", {
                          attrs: {
                            target: "instruction_invoice_number",
                            "container-class": "mr-0",
                            instructions: [
                              {
                                text: _vm.$t(
                                  "INVOICE.TABLE_INSTRUCTION.INVOICE_NUMBER_1"
                                ),
                              },
                              {
                                text: _vm.$t(
                                  "INVOICE.TABLE_INSTRUCTION.INVOICE_NUMBER_2"
                                ),
                              },
                            ],
                          },
                        }),
                        _vm._v(" " + _vm._s(label.toUpperCase()) + " "),
                      ],
                      1
                    ),
                  ]
                },
              },
            ]),
          }),
          _c(
            "b-row",
            { staticClass: "pt-1" },
            [
              _c(
                "b-col",
                { attrs: { cols: "6" } },
                [
                  _c("e-pagination-summary", {
                    attrs: {
                      "current-page": _vm.paging.currentPage,
                      "per-page": _vm.paging.pageSize,
                      total: _vm.paging.rowCount,
                      "total-on-page": _vm.paging.rowsInCurrentPage,
                    },
                  }),
                ],
                1
              ),
              _c(
                "b-col",
                { attrs: { cols: "6" } },
                [
                  _c("b-pagination", {
                    attrs: {
                      align: "right",
                      "total-rows": _vm.paging.rowCount,
                      "per-page": _vm.paging.pageSize,
                      "aria-controls": "invoices-table",
                    },
                    on: { change: _vm.pageChange },
                    model: {
                      value: _vm.paging.currentPage,
                      callback: function ($$v) {
                        _vm.$set(_vm.paging, "currentPage", $$v)
                      },
                      expression: "paging.currentPage",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm.$can("Create", "Invoice")
        ? _c("fab", {
            attrs: {
              "main-tooltip": _vm.$t("Adicionar Nota Fiscal"),
              "main-icon": "keyboard_command_key",
              actions: _vm.actions,
              "fixed-tooltip": true,
            },
            on: {
              "create-with-import": _vm.onCreateInvoiceFromCoupon,
              "create-manual": _vm.onCreateInvoice,
            },
          })
        : _vm._e(),
      _c("consult-invoice-modal", { ref: "consultInvoiceModal" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }