<template>
  <b-modal
    id="consultInvoiceModal"
    no-close-on-backdrop
    hide-footer
    size="lg"
    centered
    @hidden="onHideModal"
  >
    <template #modal-title>
      <p class="h4">
        {{ modalTitle }}
      </p>
    </template>

    <e-spinner v-if="loadingModal" />

    <b-row v-else>
      <b-col md="3">
        <FormulateInput
          id="consult_modal-return_code"
          v-model="invoice.nfeDetail.returnCode"
          type="label"
          :label="$t('Código de retorno')"
        />
      </b-col>
      <b-col md="9">
        <FormulateInput
          id="consult_modal-return_message"
          v-model="invoice.nfeDetail.returnMessage"
          type="label"
          :label="$t('Mensagem de retorno')"
        />
      </b-col>
    </b-row>
  </b-modal>
</template>

<script>
import { BModal, BRow, BCol } from 'bootstrap-vue'
import { invoiceDomains } from '@/mixins'
import { mapActions } from 'vuex'
import { getInitialInvoice } from '@/store/pages/invoice/invoice/invoice-maintain'
import ESpinner from '@/views/components/ESpinner.vue'

export default {
  components: { BModal, BRow, BCol, ESpinner },

  mixins: [],

  props: {},

  data() {
    return {
      modalTitle: '',
      loadingModal: false,
      invoice: getInitialInvoice(),
    }
  },

  computed: {},

  methods: {
    ...mapActions('pages/invoice/invoice/invoiceMaintain', { stInvoiceManage: 'invoiceManage' }),

    async show(invoice, invoiceData) {
      this.$bvModal.show('consultInvoiceModal')

      try {
        this.loadingModal = true
        if (invoice) {
          this.modalTitle = this.$t('Consulta da Nota Fiscal')
          const invoiceToConsult = await this.stInvoiceManage({
            requestType: invoiceDomains.data().invoiceRequestTypeEnum.CONSULT_INVOICE,
            invoiceId: invoice.id,
          })
          this.invoice = invoiceToConsult
        } else if (invoiceData) {
          this.modalTitle = this.$t('Retorno do Evento')
          const defaultInvoice = getInitialInvoice()
          this.invoice = {
            ...defaultInvoice,
            ...invoiceData,
          }
        }
      } catch (error) {
        this.showError({ error })
      } finally {
        this.loadingModal = false
      }
    },
    onHideModal() {
      this.$bvModal.hide('consultInvoiceModal')
    },
  },
}
</script>

<style lang="scss" scoped></style>
