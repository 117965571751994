var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-modal",
    {
      attrs: {
        id: "consultInvoiceModal",
        "no-close-on-backdrop": "",
        "hide-footer": "",
        size: "lg",
        centered: "",
      },
      on: { hidden: _vm.onHideModal },
      scopedSlots: _vm._u([
        {
          key: "modal-title",
          fn: function () {
            return [
              _c("p", { staticClass: "h4" }, [
                _vm._v(" " + _vm._s(_vm.modalTitle) + " "),
              ]),
            ]
          },
          proxy: true,
        },
      ]),
    },
    [
      _vm.loadingModal
        ? _c("e-spinner")
        : _c(
            "b-row",
            [
              _c(
                "b-col",
                { attrs: { md: "3" } },
                [
                  _c("FormulateInput", {
                    attrs: {
                      id: "consult_modal-return_code",
                      type: "label",
                      label: _vm.$t("Código de retorno"),
                    },
                    model: {
                      value: _vm.invoice.nfeDetail.returnCode,
                      callback: function ($$v) {
                        _vm.$set(_vm.invoice.nfeDetail, "returnCode", $$v)
                      },
                      expression: "invoice.nfeDetail.returnCode",
                    },
                  }),
                ],
                1
              ),
              _c(
                "b-col",
                { attrs: { md: "9" } },
                [
                  _c("FormulateInput", {
                    attrs: {
                      id: "consult_modal-return_message",
                      type: "label",
                      label: _vm.$t("Mensagem de retorno"),
                    },
                    model: {
                      value: _vm.invoice.nfeDetail.returnMessage,
                      callback: function ($$v) {
                        _vm.$set(_vm.invoice.nfeDetail, "returnMessage", $$v)
                      },
                      expression: "invoice.nfeDetail.returnMessage",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }